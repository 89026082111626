<template lang="pug">
    Aside(:ativo="contents !== null" @close="$emit('setContent', null)")#timelineContent
        h3 {{ contents.content.title.pt }}
        .content(v-html="parseContent(contents.content.content.pt)", v-if="!isComponents")
        component(:is="item.component", v-for="item, index in mapped", :key="`${index}-${item.type}`", :contents="item.contents", :specialType="item.special", v-else)
</template>

<script>
import { components, props, parseContent, dictionary as DICTIONARY_COMPONENTS } from '@/mixins/components'

export default {
    name: "component-timeline-content",
    props,
    components,
    computed: {
        isComponents() {
            return this.contents.children.length ? true : false
        },
        mapped() {
            return this.contents.children.map(item => {
                return Object.assign({}, { component: this.$options.components[DICTIONARY_COMPONENTS[item.content.type]], contents: item.content.type == 'imageGallery' ? item.content : item, type: item.content.type, special: null })
            })
        }
    },
    methods: {
        parseContent,
    }
}
</script>

<style lang="stylus" scoped src="@stylus/defaultTextos.styl"></style>
<style lang="stylus" scoped src="./TimelineContent.styl"></style>
